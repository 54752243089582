<template>
  <div class="user-detail">
    <div class="-user-detail-header">
      <el-page-header @back="$router.back()" content="用户详情">
      </el-page-header>
    </div>
    <div class="user-detail-item">
      <p class="user-detail-item-title">基本信息</p>
      <div class="detail-item-info">
        <div class="info-picture">
          <img :src="user.avatar" :alt="user.niceName">
        </div>

        <div class="info-text">
          <p class="detail-nickname">
            <span class="detail-nickname-value">
             昵称：{{user.niceName}}
            </span>
            <span class="info-icon">
            <i class="el-icon-male male" v-if="user.gender == 2"></i>
            <i class="el-icon-female female" v-else-if="user.gender == 3"></i>
          </span>
            <span class="detail-id">
              ID:{{user.uuid}}
            </span>

          </p>

          <el-row>
            <el-col :span="8" class="info-item-container">
              <div class="info-item">
                <span class="info-label">手机号:</span>
                <span class="info-value">{{user.phone}}</span>
              </div>
              <div class="info-item">
                <span class="info-label">会员等级:</span>
                <span class="info-value">{{user.vipName}} </span>
              </div>

            </el-col>
            <el-col :span="8" class="info-item-container">
              <div class="info-item">
                <span class="info-label">最近访问:</span>
                <span class="info-value">{{new Date(user.lastAccessAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
              </div>
              <div class="info-item">
                <span class="info-label">最近消费:</span>
                <span class="info-value" v-if="user.lastFeeAt > 0">{{new Date(user.lastFeeAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
                <span class="info-value" v-else>暂无消费</span>
              </div>
            </el-col>
            <el-col :span="8" class="info-item-container">
              <div class="info-item">
                <span class="info-label">来源渠道:</span>
                <span class="info-value">{{user.fromChanel | filterChanel}}</span>
              </div>
              <div class="info-item">
                <span class="info-label">注册时间:</span>
                <span class="info-value">{{new Date(user.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}} </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="detail-trading-information">
        <div class="detail-item">
          <div class="detail-item-label">
            累计支付订单数
          </div>
          <div class="detail-item-value">
            {{user.feeTimes}}
          </div>
        </div>
        <div class="detail-item">
          <div class="detail-item-label">
            累计支付金额
          </div>
          <div class="detail-item-value">
            ¥{{(user.fee / 100) | F1000}}
          </div>
        </div>
        <div class="detail-item">
          <div class="detail-item-label">
            累计售后订单数
          </div>
          <div class="detail-item-value">
            {{user.backMoneyTimes}}
          </div>
        </div>
        <div class="detail-item">
          <div class="detail-item-label">
            累计退款金额
          </div>
          <div class="detail-item-value">
            ¥{{(user.backMoney / 100) | F1000}}
          </div>
        </div>
      </div>
    </div>
    <div class="user-detail-item">
      <!--      <p class="user-detail-item-title">相关信息</p>-->
      <div style="margin: 0 8px">
        <el-tabs v-model="option.comp">
          <el-tab-pane :name="option.comp" :key="option.comp" :label="option.name"
                       v-for="option in options"></el-tab-pane>
        </el-tabs>
        <keep-alive>
          <component v-bind:is="option.comp"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "userData",
    data() {
      return {
        user: {},
        option: {
          comp: 'member',
          path: '',
        },
        options: [
          {
            name: '成长记录',
            comp: 'member',
          },
          {
            name: '推广用户',
            comp: 'user',
          },
          {
            name: '订单记录',
            comp: 'order',
          },
          {
            name: '积分明细',
            comp: 'count',
          },
          {
            name: '优惠券',
            comp: 'ticket',
          },
        ],
      }
    },
    async mounted() {
      let uuid = this.$route.params.uuid
      this.getDetail(uuid)
    },
    methods: {
      getDetail: function (uuid) {
        this.$api('mkt.user.detail', {uuid: uuid}).then(data => {
          this.user = data
        })
      },
      router: function (path) {
        this.$router.push({
          path: path
        });
      }
    },
    filters: {
      filterChanel: function (ch) {
        switch (ch) {
        case "wx":
          return "微信小程序"
        }
        return "未知"
      },
    }
  };
</script>
<style lang="scss" scoped>
  .user-detail {
    @include container();

    &-item {
      margin-top: $container-padding;
      margin-left: $middle-space;
      margin-right: $middle-space;

      &-title {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        opacity: 0.85;
        margin-left: $small-space;
        margin-bottom: $small-space;
      }

      .info-icon {
        display: inline-block;
        margin-right: $small-space;

        i {
          background: #f5f5f5;
          border-radius: 50%;
          padding: 4px;
          font-weight: bold;
        }

        .male {
          color: #00aaee;
        }

        .female {
          color: #f56c6c;
        }
      }

      &-title:before {
        display: inline-block;
        width: 4px;
        height: 16px;
        margin-right: $small-space;
        background: $theme-color;
        vertical-align: -10%;
        content: " ";
      }

      .detail-item-info {
        display: flex;
        align-content: center;
        align-items: center;
      }

      .info-picture {
        width: 216px;
        height: 144px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          width: 88px;
          height: 88px;
          border-radius: 50%;
          margin-bottom: 17px;
        }
      }

      .info-text {
        flex: 1;

        .detail-nickname {
          margin-bottom: $middle-space;

          &-value {
            font-size: 18px;
            margin-right: $middle-space;
            font-weight: 500;
            color: #000000;
            opacity: 0.85;
          }
        }

        .detail-id {
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          opacity: 0.65;
        }
      }

      .info-item-container {
        .info-item {
          margin-bottom: $small-space;
          font-size: 14px;
          font-weight: 400;

          .info-label {
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            margin-right: $small-space;
          }

          .info-value {
            color: rgba(0, 0, 0, 0.75);
          }
        }
      }
    }
  }


  .detail-trading-information {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: rgba(98, 60, 235, 0.05);
    border-radius: 8px;

    .detail-item {
      padding: $middle-space 0;
      min-width: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      -webkit-flex-shrink: 1;
      flex-shrink: 1;
      text-align: center;

      .detail-item-label {
        font-size: 13px;
        font-weight: 400;
        color: #000000;
        opacity: 0.45;
      }

      .detail-item-value {
        margin-top: $small-space;
        margin-bottom: $small-space;
        font-size: 22px;
        font-weight: 500;
        color: $color-black;
      }

      .el-tabs__nav-wrap:after {
        height: 0;
      }
    }

    .detail-item:not(:last-child) {
      margin-right: 24px;
    }
  }

</style>
